import React, { useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { Col } from "react-bootstrap"
import parse from "html-react-parser"
import Layout from "../components/layout"

import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

const Page = ({ data }) => {
  const page = data?.allWpPage?.edges?.[0]?.page

  return (
    <Layout>
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="mb-5">
          <Col xs={12}>
            <h3
              className="text-center pl-3 pr-3 post-title font-weight-bolder"
              itemProp="headline"
            >
              {parse(page?.title.toUpperCase())}
            </h3>
            <small className="text-center font-small d-block">
              {new Date(page?.date).toDateString()}
            </small>
          </Col>
        </header>

        <Col xs={12}>
          {!!page?.content && (
            <section itemProp="articleBody">{parse(page.content)}</section>
          )}
        </Col>
      </article>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query GetPagesByID($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        page: node {
          id
          title
          uri
          date
          template {
            templateName
          }
          content
          featuredImage {
            node {
              localFile {
                id
                childImageSharp {
                  gatsbyImageData(quality: 80, width: 700)
                }
              }
            }
          }
        }
      }
    }
  }
`
